#tour-sections {
  position: relative;
}

.phone-background {
  width: 360px;
  height: 613px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background: url(../images/phone-mockup.png) center center no-repeat;
  
  .header & {
    height: 703px;
    background: url(../images/phone-mockup-no-shadow.png) center 90px no-repeat;
    width: 280px;
    @include breakpoint(md) {
      width: 360px;
    }
  }
  .iframe-container {
    position: absolute;
    left: 58px;
    top: 90px;
    width: 245px;
    height: 427px;
    background: $black;
    
    .header & {
      top: 180px;
      left: 18px;
      @include breakpoint(md) {
      left: 58px;
    }
    }
  }
}
.laptop-background {
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  height: 531px;
  display: block;
  vertical-align: middle;
  position: relative;
  background: url(../images/laptop_mockup.png) center center no-repeat;
  @include breakpoint(md) {
    width: 850px;
    margin: 0 auto;
  }
  .iframe-container {
    position: absolute;
    left: 50%;
    margin-left: -310px;
    top: 65px;
    height: 50px;
    width: 621px;
    height: 387px;
    background: $black;
    @include breakpoint(md) {
      left: 114px;
      margin-left: 0;
    }
  }
}
.combo-background {
  position: relative;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  height: 531px;
  display: block;
  background: url(../images/laptop_mockup.png) center center no-repeat;
  @include breakpoint(lg) {
    width: 1070px;
    background: url(../images/combination-mockup.png) center center no-repeat;
    margin: 0 auto;
  }
  
  .iframe-container-browser {
    position: absolute;
    left: 50%;
    margin-left: -310px;
    top: 65px;
    height: 50px;
    width: 621px;
    height: 387px;
    background: $black;
    @include breakpoint(md) {
      left: 50%;
      margin-left: -310px;
    }
    @include breakpoint(lg) {
      left: 127px;
      margin-left: 0;
    }
  }
  .iframe-container-mobile {
    display: none;
    @include breakpoint(lg) {
      display: block;
      position: absolute;
      left: 772px;
      top: 94px;
      width: 245px;
      height: 427px;
      background: $black;
    }
  }
}
.phone-iso {
  background-image: url(../images/phone-isometric-mockup.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  background-size: 600px 381px;

  
}

.tour {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  @include breakpoint(sm) {
    padding-top: 0;
  }
}

.tour-nav {
  background: rgba($black, 0.75);
  z-index: 10;
  @include breakpoint(sm) {
    display: table;
    height: 100%;
    background: none;
    padding-right: 30px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: table;
    width: 100%;
    height: 50px;
    @include breakpoint(sm) {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      padding: 0 15px;
      width: 65px
    }
    li {
      margin: 5px 10px;
      display: table-cell;
      vertical-align: middle;
      font-size: 1.5em;
      position: static;
      @include breakpoint(sm) {
        display: block;
        margin: 20px 0;
      }
      a {
        color: rgba($white, 0.5);
        padding: 0;
        display: inline-block;
          @include breakpoint(sm) {
            color: $grey;
          }
         &:focus, &:active {
           color: rgba($white, 0.5);
           @include breakpoint(sm) {
            color: $grey;
          }
         } 
        &:hover {
          .no-touch & {
            color: $white;
            @include breakpoint(sm) {
              color: darken($grey, 25%);
            }

          }
            
            
          
        }
        &:hover, &:focus {
          text-decoration: none;
          background: none;
        }
        &.current {
        
            color: $white;
            @include breakpoint(sm) {
            color: $brand-primary;
          }
          
        
      }

      }
      &.active {
        a {
          color: $white;
            @include breakpoint(sm) {
            color: $brand-primary;
          }
        }
      }
    }
  }
  &.affix-top {
    position: absolute;
    width: 100%;
    top:0;
    right: 0;
    @include breakpoint(sm) {
      
      height: 100vh;
      width: 65px;
      right: 0;
      top:0;
      bottom: 0;
    }
    @include breakpoint(lg) {
      width: 85px;
    }
    
  }
  &.affix {
    position: fixed;
    top:0;
    right:0;
    width: 100%;
    @include breakpoint(sm) {
      
      height: 100%;
      width: 65px;
      right: 0;
      top: 0;
    }
    @include breakpoint(lg) {
      width: 85px;
    }
  }
  &.affix-bottom {
    position: absolute;
    width: 100%;
    bottom:0;
    right: 0;
    @include breakpoint(sm) {
      
      height: 100vh;
      width: 65px;
      right: 0;
      top:0;
      bottom: 0;
    }
    @include breakpoint(lg) {
      width: 85px;
    }
  }
}

.tour-page {
  border-bottom: 1px solid lighten($grey, 40%);
  padding-top: 40px;
  padding-bottom: 40px;
  

  
  @include breakpoint(sm) {
   
   
  }
  &#style, &#content, &#publish {
    padding-bottom: 40px;
    @include breakpoint(sm) {
      padding-bottom: 60px;
    }
  }
  &#style {
    background-image: url(../images/phone-isometric-mockup.jpg);
    background-position: center -70px;
    background-repeat: no-repeat;
    background-size: 420px 267px;
    padding-top: 120px;
    @include breakpoint(sm) {
      background: none;
      padding-top: 60px;
    }

  }
  &#content {
    background-image: url(../images/phone-isometric-mockup.jpg);
    background-position: center -70px;
    background-repeat: no-repeat;
    background-size: 420px 267px;
    padding-top: 120px;
    @include breakpoint(sm) {
      background: none;
      padding-top: 60px;
    }

  }
  &#publish {
    background-image: url(../images/laptop_mockup.png);
    background-position: 70px 0;
    background-repeat: no-repeat;
    background-size: 400px 250px;
    padding-top: 190px;
    @include breakpoint(sm) {
      background: none;
      padding-top: 60px;
    }

  }
  &#engage {
    background-image: url(../images/phone-isometric-mockup.jpg);
    background-position: center -70px;
    background-repeat: no-repeat;
    background-size: 420px 267px;
    padding-top: 120px;
    @include breakpoint(sm) {
      /* background: none; */
      padding-top: 90px;
      background-size: 600px 381px;
    }

  }
  &#analyse {
    background-image: url(../images/laptop_mockup.png);
    background-position: 70px 0;
    background-repeat: no-repeat;
    background-size: 400px 250px;
    padding-top: 190px;

    @include breakpoint(sm) {
      background: none;
      padding-top: 60px;
      padding-bottom: 0;
    }

  }
}