.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

 
.turnstyle-icon {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  background-image: url(/images/ts-icon-grey.svg);
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  backface-visibility: hidden;
}

.slide-content {
  height: 100vh;
  min-height: 600px;
}

.slick-slider {
  height: 100%;
  overflow: hidden;
  z-index: 1;
  .slick-track, .slick-list {
    height: 100%;
  }
  .slide-content {
    background-size: cover;
    background-position: center;
    color: $white;
    min-height: 600px;
    > .container {
      height: 100%;
    }
  }
}
.slick-dots {
  bottom: 0;
  li {
    button:before {
      color: $white !important;
    }
    &.slick-active {
      button-before {
        color: $white !important;
      }
    }
  }
}
.slick-prev, .slick-next {
  z-index: 1001;
  width: 20px;
  height: 50px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 20px;
  transition: all .2s ease;
  &:before {
    content: '';
  }
  @include breakpoint(sm) {
    margin-top: -10px;
  }

  
}
.slick-prev {
  left: 25px;
  background-image: url(../images/arrow-left.svg);
  opacity: 0.7;
  &:hover, &:focus {
    background-image: url(../images/arrow-left.svg);
    opacity: 1;
  }
  @include breakpoint(xs) {
    
    left: 12px;
  }
}
.slick-next {
  right: 25px;
  background-image: url(../images/arrow-right.svg);
  opacity: 0.7;
  &:hover, &:focus {
    background-image: url(../images/arrow-right.svg);
    opacity: 1;
  }
  @include breakpoint(xs) {
    
    right: 12px;
  }
}
.slick-list {
    .slick-loading & {
        background: $white slick-image-url("../images/ajax-loader.gif") center center no-repeat;
    }
}


.trial-feature {
  padding-top: 50px;
  padding-bottom: 50px;
  @include breakpoint(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

}

.footer {
  background: $text-color;
  color: $white;
  padding: 40px 0 100px;
  @include breakpoint(sm) {
    padding: 60px 0;
  }
  a {
    color: $white;
  }
  .list-inline {
    text-align: center;
    @include breakpoint(md) {
      text-align: left;
    }
    li {
      display: block;
      font-weight: 100;
      @include breakpoint(sm) {
        display: inline-block;
        vertical-align: middle;
      }
      a, span {
        color: $white;
        display: block;
        padding: 6px 10px;
        cursor: pointer;
        &:hover {
          color: lighten($brand-primary, 20%);
          text-decoration: none;
        }
      }
    }

  }
  .small {
    color: lighten($grey, 30%);
    font-weight: 100;
  }
}
.footer-right {
  text-align: center;
  @include breakpoint(md) {
    text-align: right;
  }
}
.logo-rev {
  width: 120px;
    height: 74px;
    display: inline-block;
    background-image: url(../images/logo-stack-rev.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px auto;
    @include breakpoint(sm) {
      width: 180px;
    height: 41px;
      background-image: url(../images/logo-wide-rev.svg);
      margin: 0 0 20px 0;
    }
}
