

.table.price-table {
	.cost {
		font-size: 1.1em;
		@include breakpoint(sm) {
			font-size: 2em;
		}
		span {
			display: block;
			font-size: 15px;
		}
	}
	button {
		border: none;
		padding: 0;
		background: none;
		font-size: 1.2em;
		display: inline-block;
		vertical-align: text-bottom;
		color: $brand-primary;
		display: inline-block;
		
		&:hover, &:focus {
			outline: none;
			color: $black;
		}
	}
	th {
		font-weight: 1.2em;
		font-weight: 300;
		border-bottom: none;
		h3, .h3 {
			margin:0;
		}
	}
	th, td {
		text-align: center;
		vertical-align: middle;
		@include breakpoint(sm) {
			&:first-child {
				text-align: left;
			}
		}
		
		@include breakpoint(sm) {
			padding: 20px;
		}
		
		
	}
	.icon-icon-tick {
		font-size: 1.5em;
	}
	.feature-col {
		background: lighten($gray-lighter, 3%);
		@include breakpoint(xs) {
			font-weight: 600;
		}
	}
	.basic-col {
		background: $grey;
		color: $white;
	}
	.standard-col {
		color: $brand-primary;
		@include breakpoint(sm) {
			background: $brand-primary;
			color: $white;
		}
	}
	.premium-col {
		color: $secondary;
		@include breakpoint(sm) {
			background: $secondary;
			color: $white;
		}
	}

	@include breakpoint(xs) {
		 
      border: 0;
    	font-size: 0.85em;

    thead {
      display: none;
    }

    tr {
      margin-bottom: 20px;
      display: block;
      border: 1px solid lighten($grey, 30%);
      border-radius: 4px;
    }

    td {
      display: block;
      text-align: left;
      background: none;
    }

    td:last-child {
      border-bottom: 0;
    }

    td:before {
      content: attr(data-label);
      float: none;
      font-weight: 300;
      line-height: 1.3;
      padding: 2px 0;
      display: inline-block;
      vertical-align: middle;
      width:49%;
      margin-right: 4%;
    }
    .feature-title {
    	display: inline-block;
    	vertical-align: middle;
    	width: 45%;
    }
	}
}

.faqs {
	h4 {
		margin-top: 40px;
	}
}

