.cd-section {
  height: 100vh;
  min-height: 600px;
}

.page-header {
  height: 75vh;
  min-height: 400px;
  border: none;
  padding:120px 0 0 0;
  margin: 0;
  @include breakpoint(sm) {
    height: 50vh;
    padding: 80px 0 20px 0;
  }
}

.table-container {
  display: table;
  height: 100%;
  width: 100%;
  max-width: 84%;
  margin: 0 auto;
  table-layout: fixed;
  @include breakpoint(sm) {
    max-width: 610px;
  }
  @include breakpoint(md) {
    max-width: 700px;
  }
  @include breakpoint(lg) {
    max-width: 900px;
  }


  .left-cell, .right-cell, .center-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 20px 15px 0 15px;
    #home & {
      padding: 100px 15px 0 15px;
    }

  }
}

.purple {
  color: $secondary;
}



/* .arrow_box {
  position: relative;
  background: $white;
  
}
.arrow_box:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
  
} */
.align-bottom {
  vertical-align: bottom !important;
}


.arrow_box {
  position: relative;
  background: #ffffff;
  &.light-grey {
    
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: lighten($gray, 50%);
    }
  }
}
.arrow_box:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
  @include breakpoint(xs) {
    display: none;
  }
}