@-webkit-keyframes flipNew {

  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }



  100% {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

}

@keyframes flipNew {
  

  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }



  100% {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
}

.animated.flipNew {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  -webkit-animation-name: flipNew;
          animation-name: flipNew;
}




@-ms-keyframes tsspin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes tsspin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes tsspin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes tsspin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}


.ts-elevation {
    
    width: 50px;
    height: 50px;
    background-image: url(../images/ts-elevation.svg);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation-name: tsspin;
    -webkit-animation-duration: 3000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: tsspin;
    -moz-animation-duration: 3000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: tsspin;
    -ms-animation-duration: 3000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: tsspin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    display: table-cell;
    vertical-align: middle;
    text-align: center; 
}

