.header {
  position: relative;
  nav {
    display: block;
    text-align: center;
  }

}
.header-content {
  position: absolute;
  width: 100%;
  z-index: 100;
  @include breakpoint(sm) {
    position: relative;
  }
}

.brand {
  display: block;
  margin: 30px auto 15px auto;
  text-align: center;
  @include breakpoint(sm) {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
}
.logo {
  display: inline-block;
  background-image: url(../images/logo-stack-pos.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 198px;
  height: 45px;
  transition: width .2s ease;
  @include breakpoint(sm) {
    display: block;
    background-image: url(../images/logo-wide-pos.svg);
    width: 198px;
    height: 45px;
  }
  @include breakpoint(md) {
    width: 198px;
    height: 45px;
  }
}

.sticky-band {
  width: 100%;
  
  position: static;
  z-index: 1;
  top:0;
  left:0;
  @include breakpoint(sm) {
    position: fixed;
  }

  .cta {
    display: inline-block;
    float: right;
    .inline-block {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.2;
      font-size: 0.9em;
      margin-right: 10px;
    }
  }
  &.affix-top {
    padding: 0;
    @include breakpoint(sm) {
      background: none;
      transition: all .3s ease;
      padding: 40px;
    }

    .logo {
      background-image: url(../images/logo-stack-pos.svg);
      #home & {
        background-image: url(../images/logo-stack-pos.svg);
      }
      @include breakpoint(sm) {
        background-image: url(../images/logo-wide-pos.svg);
        #home & {
          background-image: url(../images/logo-wide-pos.svg);
        }
      }
    }
    
    .cta {
      @include breakpoint(sm) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &.affix {
    padding: 0;
    @include breakpoint(sm) {
      background: rgba($black, 0.75);
      padding: 10px 20px;
      transition: all .3s ease;
    }
    .logo {
      background-image: url(../images/logo-stack-pos.svg);
      #home & {
        background-image: url(../images/logo-stack-rev.svg);
      }
      @include breakpoint(sm) {
        background-image: url(../images/logo-wide-rev.svg);
        width: 198px;
        height: 45px;
        #home & {
          background-image: url(../images/logo-wide-rev.svg);
        }
      }
    }
    .cta {
      @include breakpoint(sm) {
        opacity: 1;
        visibility: visible;
        color: $white;
        text-align: right;
        margin: 4px 0;
        h4, p {
          margin: 0;
        }
      }
    }
  }

}
.fix-button {
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  width: 100%;
  height: 50px;
  padding: 15px;
  border-radius: 0;
  background: rgba($black, 0.75);
  color: $white;
  text-align: center;
  z-index: 1000;
  &:hover, &:focus {
    text-decoration: none;
    background: $black;
    color: $white;
  }
  @include breakpoint(sm) {
    position: static;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    z-index: 1;
    width: auto;
    background: $brand-primary;
    &:hover, &:focus {
      background: $dark-primary;
    }
  }
}

.main-nav {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding:0;
  @include breakpoint(sm) {
    text-align: right;
    position: absolute;
    top:0;
    right:0;
    padding: 40px;
  }
  li {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    a, span {
      padding: 10px 12px;
      color: $black;
      border-radius: 4px;
      #home & {
          color: $grey;
        }
      @include breakpoint(sm) {
        padding: 10px 15px;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: $dark-primary;
        #home & {
          background: rgba($black, 0.2);
          color: $grey;
        }

      }
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    &.active {
      a, span {
        color: $brand-primary;
      }
    }
  }
}
