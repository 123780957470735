
.bullets-row {
  padding:0;
  list-style: none;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 40px;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  li {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    min-height: 1px;
    margin-bottom: 40px;
    @include breakpoint(sm) {
      display: inline-block;
      width: 49%;
      vertical-align: top;
    }
    @include breakpoint(md) {
      width: 32%;
    }
    .bullet {
      position: relative;
      padding-left: 40px;
      width: 100%;
      [class^="icon-"], [class*=" icon-"] {
        font-size: 2em;
        position: absolute;
        top:0;
        left:0;
        
      }
      
      
    }
  }
  &.goers li .bullet span:first-child {
    color: $brand-primary;
  }
  &.organisers li .bullet span:first-child {
    color: $secondary;
  }
}