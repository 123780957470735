/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 19, 2015 */



/* @font-face {
    font-family: 'brix_slablight';
    src: url('../fonts/brixslab-light-webfont.eot');
    src: url('../fonts/brixslab-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/brixslab-light-webfont.woff2') format('woff2'),
         url('../fonts/brixslab-light-webfont.woff') format('woff'),
         url('../fonts/brixslab-light-webfont.ttf') format('truetype'),
         url('../fonts/brixslab-light-webfont.svg#brix_slablight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'brix_slablight_italic';
    src: url('../fonts/brixslab-lightitalic-webfont.eot');
    src: url('../fonts/brixslab-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/brixslab-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/brixslab-lightitalic-webfont.woff') format('woff'),
         url('../fonts/brixslab-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/brixslab-lightitalic-webfont.svg#brix_slablight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

} */




@font-face {
    font-family: 'f37_glaser_stencildemi';
    src: url('../fonts/f37-glaser-demi-webfont.eot');
    src: url('../fonts/f37-glaser-demi-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/f37-glaser-demi-webfont.woff2') format('woff2'),
         url('../fonts/f37-glaser-demi-webfont.woff') format('woff'),
         url('../fonts/f37-glaser-demi-webfont.ttf') format('truetype'),
         url('../fonts/f37-glaser-demi-webfont.svg#f37_glaser_stencildemi') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'f37_glaser_stencilmedium';
    src: url('../fonts/f37-glaser-medium-webfont.eot');
    src: url('../fonts/f37-glaser-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/f37-glaser-medium-webfont.woff2') format('woff2'),
         url('../fonts/f37-glaser-medium-webfont.woff') format('woff'),
         url('../fonts/f37-glaser-medium-webfont.ttf') format('truetype'),
         url('../fonts/f37-glaser-medium-webfont.svg#f37_glaser_stencilmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon.eot?szttc7');
    src:url('../fonts/icomoon.eot?#iefixszttc7') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?szttc7') format('truetype'),
        url('../fonts/icomoon.woff?szttc7') format('woff'),
        url('../fonts/icomoon.svg?szttc7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-icon-cap:before {
    content: "\e615";
}
.icon-icon-crown:before {
    content: "\e616";
}
.icon-icon-shirt:before {
    content: "\e617";
}
.icon-icon-tshirt:before {
    content: "\e618";
}
.icon-icon-tick:before {
    content: "\e614";
}

.icon-icon-explore:before {
    content: "\e613";
}

.icon-icon-close:before {
    content: "\e612";
}

.icon-icon-info:before {
    content: "\e600";
}
.icon-icon-update:before {
    content: "\e601";
}
.icon-icon-alert:before {
    content: "\e602";
}
.icon-icon-analyse:before {
    content: "\e603";
}
.icon-icon-award:before {
    content: "\e604";
}
.icon-icon-calendar:before {
    content: "\e605";
}
.icon-icon-content:before {
    content: "\e606";
}
.icon-icon-engage:before {
    content: "\e607";
}
.icon-icon-insights:before {
    content: "\e608";
}
.icon-icon-lightbulb:before {
    content: "\e609";
}
.icon-icon-live-feedback:before {
    content: "\e60a";
}
.icon-icon-map:before {
    content: "\e60b";
}
.icon-icon-microphone:before {
    content: "\e60c";
}
.icon-icon-mobile:before {
    content: "\e60d";
}
.icon-icon-publish:before {
    content: "\e60e";
}
.icon-icon-share:before {
    content: "\e60f";
}
.icon-icon-style:before {
    content: "\e610";
}
.icon-icon-ticket:before {
    content: "\e611";
}






body {
    font-size: 15px;
    line-height: 1.5;
    color: $text-color;
    font-family: "museo-slab", sans-serif;
    font-weight: 300;
    height: 100%;
}

h1, .h1 {
    font-family: 'f37_glaser_stencildemi', sans-serif;
    letter-spacing: 0.05em;
    font-size: 2.2em;
    
    @include breakpoint(md) {
        font-size: 2.8em;
    }
    @include breakpoint(lg) {
        font-size: 3.5em;
        font-family: 'f37_glaser_stencilmedium';
    }
    .slider & {
        @include breakpoint(xs) {
            font-size: 1.8em;
        }
    }
}
h2, .h2 {
    font-family: 'f37_glaser_stencildemi', sans-serif;
    font-size: 1.8em;
    letter-spacing: 0.05em;
    @include breakpoint(sm) {
        font-size: 2em;
    }
    @include breakpoint(md) {
        font-size: 2.4em;
    }
    @include breakpoint(lg) {
        font-size: 3.3em;
        font-family: 'f37_glaser_stencilmedium';
    }
}
h3, .h3 {
    font-size: 1.5em;
    font-weight: 100;
    @include breakpoint(sm) {
        font-size: 1.6em;
    }
    @include breakpoint(md) {
        font-size: 1.8em;
    }
}
h4, .h4 {
    font-size: 1em;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: 700;
    
}
p {
    &.lead {
        font-weight: 100;
    }
}

a {
    transition: all .2s ease;
    &:focus {
        outline: none;
    }
}

.btn, .fix-button {
    text-transform: uppercase;
    font-size: 0.9em;
    border-radius: 4px;
    padding: 10px 16px;
    border: none;
    transition: all .2s ease;
    
    @include breakpoint(sm) {
        margin: 0 5px;
    }
    @include breakpoint(md) {
        min-width: 150px;
        
    }
}
.btn-primary {
    background: $brand-primary;
    color: $white;
    &:hover, &:focus {
        background: $dark-primary;
    }
}

.btn-purple {
    background: $secondary;
    color: $white;
    &:hover, &:focus {
        background: darken($secondary, 10%);
        color: $white;
    }
}

.signin-form {
    margin: 40px 0;
    @include breakpoint(md) {
        margin: 60px 0;
    }
    @include breakpoint(lg) {
        margin: 60px 0 80px;
    }
    
}
.signup-form {
    margin-bottom: 40px;
    @include breakpoint(md) {
        margin-bottom:: 60px;
    }
    @include breakpoint(lg) {
        margin-bottom:: 80px;
    }
}
.form-control {
        border: 2px solid lighten($text-color, 40%);
        box-shadow: none;
        padding: 15px;
        height: auto;
        &:focus {
            border: 2px solid $brand-primary;
            box-shadow: none;
            outline: none;
        }
        
    }
    .has-error {
        .form-control {
            border-color: $brand-danger;
        }
        .help-block {
            color: $brand-danger;
        }
    }