
.overlay-container {
	position: fixed;
	z-index: 1000;
	background: rgba($white, 0.95);
	overflow: auto;

	top:0;
	right: 0;
	height: 100%;

	.logo {
		width: 152px;
	    height: 60px;
  	}

	&#signinOverlay {
		width: 100%;

		transition: transform .2s ease-out;
		transform: translateX(100%);

		&.active {
			transform: translateX(0%);
		}

		@include breakpoint(sm) {
			width: 70%;
		}
		@include breakpoint(md) {
			width: 50%;
		}
		.overlay.overlay-content {
			max-width: 400px;
		}

	}

	&#wizardOverlay {
		width: 95%;

		border: none;

		transition: transform .2s ease-out;
		transform: translateX(100%);
		&.active {
			transform: translateX(0%);
		}
	}
	
	.overlay {

		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity .3s ease;
		z-index: 1;
		
		
		&.active {
			z-index: 2;
			display: block;
			opacity: 1;
		}
		.overlay-content {
			padding: 60px 40px;
			overflow: auto;
			text-align: center;
			display: block;
			margin: 0 auto;

			width: 100%;
			height: 100%;
			
			iframe {
				width: 100%;
				height: 100%;
			}			
		}		
	}
}
.no-csstransforms {
	.js-overlay-button {
		display: none;
	}
	 .overlay-container {
			display: none;
			.active & {
				display: block;
			}
		}
		}
		
.overlay-open {
	overflow: hidden;
}

.overlay-backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	background: rgba($black, 0.75);
	display: none;
	html.overlay-open & {
		display: block;
		z-index: 999;
	}
	
}

.close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    border: none;
    outline: none;
    z-index: 1000;
    background: none;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    padding:0;
    font-size: 1.5em;
    line-height: 1.5;
    text-align: center;
    color: $black;
    &:hover, &:focus {
    	opacity: 1;
    }
}
