// Add bootstrap overrides here too


$black: #221f1f;
$white: #ffffff;
$grey: #7c8083;

$text-color: $grey;
$brand-primary: #2fc2b9;
$dark-primary: #25737d;
$secondary: #8d4a82;
$dark-secondary: #3d1d25;
$brand-danger: #e54444 !default;

$link-color: $brand-primary;
$link-hover-color: $dark-primary;




$padding-base-vertical:     10px !default;
$padding-base-horizontal:   16px !default;

$padding-large-vertical:    16px !default;
$padding-large-horizontal:  24px !default;

$grid-gutter-width: 30px;



$btn-default-color:              $text-color !default;
//$btn-default-bg:                 transparent !default;
$btn-default-border:             $text-color !default;



$slick-font-path: "../fonts/" !default;